import { hydrate } from "@/utils/form";

export default class News {
    id = null;
    title = '';
    shortContent = '';
    content = '';
    mainPhoto = null;
    publicationStartDate = null;
    publicationEndDate = null;
    enabled = true;
    club = JSON.parse(localStorage.getItem('selectedClub'));

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
            if(options.deserialize) {
                this.deserialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
    deserialize (object) {
        hydrate(this, object);
    }
}
