<template>
  <div class="container-fluid">
    <b-card>
      <b-row class="row-style" no-gutters>
        <b-col class="mt-3 ml-3" :cols="$store.getters['layout/getInnerWidth']>=991 ? '6' : '4'">
          <d-body-nav-bar :tabs="$store.getters['menu/getTabs'].newsIndex"/>
        </b-col>
        <b-col
          v-if="$store.getters['layout/getInnerWidth'] >= 991"
          class="mt-3 align-content-end text-right mr-2"
        >
          <d-button
            text="views.news.list.new-actuality"
            class="ml-2 d-btn btn d-btn-primary font-text-title"
            icon="fa fa-plus-circle mr-1"
            @on:button-click="newActuality"
          />
        </b-col>
      </b-row>
      <div>
        <b-row no-gutters>
          <b-col align="right" v-if="$store.getters['layout/getInnerWidth']<991">
            <d-button
              text="views.news.list.new-actuality"
              class="d-btn custom-margin-top btn d-btn-primary font-text-title"
              icon="fa fa-plus-circle mr-1"
            />
          </b-col>
        </b-row>
        <d-table
          identifier="news"
          :tablefields="tableFields"
          :items="items"
          :per-page="itemsPerPage"
          :totalRows="totalRows"
          :is-busy="isBusy"
          :per-page-enabled="true"
          @on:news:delete="onNewsDelete"
          @on:data-table-btn:edit="onNewsEdit"
          @on:news:rar="onNewsRar"
          @on:news:unrar="onNewsUnrar"
          @current-page:update="onCurrentPageUpdate"
          @per-page:update="onPerPageUpdate"
        >
        </d-table>
      </div>
    </b-card>
  </div>
</template>
<script>

import {deleteClubNews, getClubNews, putClubNews} from "@api/doinsport/services/news/news.api";
import News from "@/classes/doinsport/News";

export default {
  data: () => ({
    filter: '&order[createdAt]=desc',
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    isBusy: true,
    timer: null,
    items: [],
  }),
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {
          key: "title", label: this.$t("views.subscriptions.index.table.name"), sortable: true
        },
        {
          key: "shortContent",
          label: this.$t("views.news.list.table.shortContent"),
        },
        {
          key: "publicationStartDateAsString",
          class: this.alignCellsToLeft,
          label: this.$t("views.news.list.table.publicationStartDate"),
          sortable: true
        },
        {
          key: "publicationEndDateAsString",
          class: this.alignCellsToLeft,
          label: this.$t("views.news.list.table.publicationEndDate"),
        },
        {
          key: "actualityStatus",
          class: this.alignCellsToLeft,
          label: this.$t("views.news.list.table.status"),
          sortable: true
        },
        {key: "actions", label: this.$t("general.actions.actions")},
      ];
    },
  },
  methods: {
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadNews();
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadNews();
    },
    onNewsEdit(news) {
      this.$router.push({name: 'news_update', params: {id: news.id}})
    },
    onNewsRar(news) {
      putClubNews(news.id, {enabled: false}).then((response) => {
        this.loadNews();
      })
    },
    onNewsUnrar(news) {
      putClubNews(news.id, {enabled: true}).then((response) => {
        this.loadNews();
      })
    },
    onNewsDelete(news) {
      deleteClubNews(news.id)
        .then((response) => {
          this.loadNews();
        })
      ;
    },
    newActuality() {
      this.$router.push({name: 'new_news'});
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadNews();
      }, 400);
    },
    loadNews() {
      this.isBusy = true;
      this.items = [];

      getClubNews(this.itemsPerPage, this.currentPage, this.filter)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          for (const dataNews of response.data['hydra:member']) {
            const news = new News(dataNews, {serialize: true});

            news.actions = ['edit', 'rar', 'unrar', 'delete'];

            if (news.publicationStartDate === null) {
              news.actions = ['edit', 'delete'];
            } else {
              if (news.enabled) {
                news.actions = ['edit', 'rar', 'delete'];
              } else {
                news.actions = ['edit', 'unrar', 'delete'];
              }
            }

            news.publicationStartDateAsString = news.publicationStartDate ?
              this.$moment(news.publicationStartDate).format('DD-MM-YYYY') : this.$t('general.actions.undefined');
            news.publicationEndDateAsString = news.publicationEndDate ?
              this.$moment(news.publicationEndDate).format('DD-MM-YYYY') : this.$t('general.actions.undefined');
            news.actualityStatus = this.getActualityStatus(news);

            this.items.push(news);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    getActualityStatus(news) {
      if (!news.enabled) {
        return 'rar';
      } else {
        if (news.publicationStartDate === null) {
          return 'draft';
        } else if (this.$moment(news.publicationStartDate).diff(this.$moment()) > 0) {
          return 'programmed';
        } else if (this.$moment(news.publicationEndDate).diff(this.$moment()) < 0) {
          return 'expired';
        } else {
          return 'published'
        }
      }
    }
  },
  created() {
    this.loadNews();
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";
</style>
